import Home from "../components/Home";
import Shop from "../components/Shop";
import Login from "../components/Forms/Login";
import Register from "../components/Forms/Register";
import RedeemCode from "../components/Forms/RedeemCode";
import Lobby from "../components/Room/Lobby";
import Join from "../components/Room/Join";
import Checkout from "../components/Checkout";
import CheckoutReturn from "../components/CheckoutReturn";
import FormPage from "../components/Forms/FormPage"
import PolicyPage from "../components/policy/PolicyPage"
import Help from "../components/Help";

import ShopSingle from "components/ShopSingle";
import Play from "components/Room/Play";
import ShopGamePack from "components/ShopGamePack";
import CookiePolicy from "components/policy/CookiePolicy";
import DataProtection from "components/policy/DataProtection";
import PrivacyPolicy from "components/policy/PrivacyPolicy";
import TermsConditions from "components/policy/TermsConditions";

import GameList from "./GameList";
import GamePackList from "./GamePackList";
import GameAddonList from "./GameAddonList";
import ForgotPassword from "../components/Forms/ForgotPassword";
import ResetPassword from "../components/Forms/ResetPassword";
import ForwardingURL from "../components/ForwardingURL";
import Application from "../components/policy/Application";
import JuneGiveaway from "../components/policy/JuneGiveaway";
import FriYayGiveaway from "../components/policy/FriYayGiveaway";
import ShopAddon from "../components/ShopAddon";



const AppRoutes = [
    {
        index: true,
        path: "/",
        element: <Home />
    },
    {
        index: 2,
        path: "/login",
        element: <FormPage><Login /></FormPage>
    },
    {
        index: 3,
        path: "/register",
        element: <FormPage><Register /></FormPage>
    },
    {
        index: 4,
        path: "/redeem-code",
        element: <FormPage><RedeemCode /></FormPage>,
        private: true,
    },
    {
        index: 5,
        path: "/lobby",
        element: <Lobby />,
        private: true,
    },
    {
        index: 6,
        path: "/join",
        element: <FormPage><Join /></FormPage>,
    },
    {
        index: 7,
        path: "/checkout",
        element: <Checkout />,
    },
    {
        index: 8,
        path: "/checkout-return",
        element: <FormPage><CheckoutReturn /></FormPage>,
    },
    // Shop And Store Pages
    {
        index: 9,
        path: "/shop",
        element: <Shop />,
    },
    {
        index: 10,
        path: "/shop/herd-mentality",
        element: <ShopSingle key={'herd_mentality'} game={GameList[GameList.findIndex(game => game.gameId === 'herd_mentality')]} />,
    },
    {
        index: 11,
        path: "/shop/can-of-squirms",
        element: <ShopSingle key={'can_of_squirms'} game={GameList[GameList.findIndex(game => game.gameId === 'can_of_squirms')]} />,
    },
    {
        index: 12,
        path: "/shop/scrawl",
        element: <ShopSingle key={'scrawl'} game={GameList[GameList.findIndex(game => game.gameId === 'scrawl')]} />,
    },
    {
        index: 13,
        path: "/shop/the-chameleon",
        element: <ShopSingle key={'chameleon'} game={GameList[GameList.findIndex(game => game.gameId === 'chameleon')]} />,
    },
    {
        index: 14,
        path: "/shop/draw-along-with-dave",
        element: <ShopSingle key={'draw_with'} game={GameList[GameList.findIndex(game => game.gameId === 'draw_with')]} />,
    },
    {
        index: 15,
        path: "/shop/colour-brain",
        element: <ShopSingle key={'colour_brain'} game={GameList[GameList.findIndex(game => game.gameId === 'colour_brain')]} />,
    },
    {
        path: "/shop/pub-quiz",
        element: <ShopSingle key={'pub_quiz'} game={GameList[GameList.findIndex(game => game.gameId === 'pub_quiz')]} />,
    },
    {
        index: 16,
        path: "/shop/game-pack-1",
        element: <ShopGamePack key={'game_pack_1'} game={GamePackList[GamePackList.findIndex(game => game.gameId === 'game_pack_1')]} />,
    },
    {
        index: 17,
        path: "/shop/game-pack-2",
        element: <ShopGamePack key={'game_pack_2'} game={GamePackList[GamePackList.findIndex(game => game.gameId === 'game_pack_2')]} />,
    },
    {
        index: 18,
        path: "/play",
        element: <Play />
    },
    {
        index: 19,
        path: "/cookie-policy",
        element: <PolicyPage><CookiePolicy /></PolicyPage>
    },
    {
        index: 20,
        path: "/privacy-policy",
        element: <PolicyPage><PrivacyPolicy /></PolicyPage>
    },
    {
        index: 21,
        path: "/data-protection",
        element: <PolicyPage><DataProtection /></PolicyPage>
    },
    {
        index: 22,
        path: "/terms",
        element: <PolicyPage><TermsConditions /></PolicyPage>
    },
    {
        path: "/jubilant-june",
        element: <PolicyPage><JuneGiveaway /></PolicyPage>
    },
    {
        path: "/fri-yay-giveaway",
        element: <PolicyPage><FriYayGiveaway /></PolicyPage>
    },
    {
        index: 23,
        path: "/help",
        element: <Help />
    },
    {
        index: 24,
        path: "/forgot-password",
        element: <FormPage><ForgotPassword /></FormPage>
    },
    {
        index: 25,
        path: "/reset-password",
        element: <FormPage><ResetPassword /></FormPage>
    },
    {
        index: 26,
        path: "/shop/anagram-magic",
        element: <ShopSingle key={'anagram_magic'} game={GameList[GameList.findIndex(game => game.gameId === 'anagram_magic')]} />,
    },
    {
        index: 27,
        path: "/shop/maths-master",
        element: <ShopSingle key={'maths_master'} game={GameList[GameList.findIndex(game => game.gameId === 'maths_master')]} />,
    },
    {
        index: 28,
        path: "/shop/word-wheel",
        element: <ShopSingle key={'word_wheel'} game={GameList[GameList.findIndex(game => game.gameId === 'word_wheel')]} />,
    },
    {
        index: 29,
        path: "/shop/speedy-sums",
        element: <ShopSingle key={'speedy_sums'} game={GameList[GameList.findIndex(game => game.gameId === 'speedy_sums')]} />,
    },
    {
        index: 30,
        path: "/shop/brain-games",
        element: <ShopGamePack key={'brain_games'} game={GamePackList[GamePackList.findIndex(game => game.gameId === 'brain_games')]} />,
    },
    //{
    //    path: "/shop/you-cant-say-umm",
    //    element: <ShopSingle key={'cant_say_umm'} game={GameList[GameList.findIndex(game => game.gameId === 'cant_say_umm')]} />,
    //},
    {
        path: "/qr2023",
        element: <ForwardingURL />
    },
    {
        path: "/shop/addons/scrawl-adult",
        element: <ShopAddon key={`scrawl_adult`} addon={GameAddonList[GameAddonList.findIndex(addon => addon.addonId === 'scrawl_adult')]} />,
    },
    {
        path: "/shop/addons/colour-brain-junior",
        element: <ShopAddon key={`colour_brain_junior`}  addon={GameAddonList[GameAddonList.findIndex(addon => addon.addonId === 'colour_brain_junior')]} />,
    },
    {
        path: "/shop/big-potato-pack",
        element: <ShopGamePack key={'big_potato_pack'} game={GamePackList[GamePackList.findIndex(game => game.gameId === 'big_potato_pack')]} />,
    },
    {
        path: "ms-application",
            element: <PolicyPage><Application /></PolicyPage>
    }
];

export default AppRoutes;
