// Scrawl
import ScrawlLogo from "images/games/ScrawlLogo.png";
import ScrawlStoreBackground from "images/storeBackgrounds/scrawl.png";
import ScrawlStoreLogo from "images/storeLogos/scrawl.png";
import ScrawlCardLogo from "images/gameCards/logo/scrawl.png";
import ScrawlCardBackground from "images/gameCards/background/scrawl.png";
import ScrawlMobilePreview1 from "images/gameStorePreviews/scrawl/scrawl-mobile-1.png";
import ScrawlMobilePreview2 from "images/gameStorePreviews/scrawl/scrawl-mobile-2.png";
import ScrawlDesktopPreview1 from "images/gameStorePreviews/scrawl/scrawl-tv-ls.png";
import ScrawlDesktopPreview2 from "images/gameStorePreviews/scrawl/scrawl-desktop-2.png";
import ScrawlTinyCard from "images/tinyCards/Scrawl.png";

// Herd Mentality
import HerdLogo from "images/games/HerdLogo.png";
import HerdStoreBackground from "images/storeBackgrounds/herdMentality.png";
import HerdStoreLogo from "images/storeLogos/herdMentality.png";
import HerdCardLogo from "images/gameCards/logo/herdMentality.png";
import HerdCardBackground from "images/gameCards/background/herdMentatlity.png";
import HerdMobilePreview1 from "images/gameStorePreviews/herd/herd-mobile-1.png";
import HerdMobilePreview2 from "images/gameStorePreviews/herd/herd-mobile-2.png";
import HerdDesktopPreview1 from "images/gameStorePreviews/herd/herd-desktop-1.png";
import HerdDesktopPreview2 from "images/gameStorePreviews/herd/herd-hero.png";
import HerdTinyCard from "images/tinyCards/Herd Mentality.png";

// Can Of Squirms
import SquirmsLogo from "images/games/SquirmsLogo.png";
import SquirmsStoreBackground from "images/storeBackgrounds/squirms.png";
import SquirmsStoreLogo from "images/storeLogos/squirms.png";
import SquirmsCardLogo from "images/gameCards/logo/canOfSquirms.png";
import SquirmsCardBackground from "images/gameCards/background/canOfSquirms.png";
import SquirmsMobilePreview1 from "images/gameStorePreviews/squirms/squirms-mobile-1.png";
import SquirmsMobilePreview2 from "images/gameStorePreviews/squirms/squirms-mobile-2.png";
import SquirmsDesktopPreview1 from "images/gameStorePreviews/squirms/squirms-tv-ls.png";
import SquirmsDesktopPreview2 from "images/gameStorePreviews/squirms/squirms-desktop-2.png";
import SquirmsTinyCard from "images/tinyCards/Can of Squirms.png";

// Draw With Dave
import DrawWithLogo from "images/games/DrawWithLogo.png";
import DrawWithStoreBackground from "images/storeBackgrounds/drawWith.png";
import DrawWithStoreLogo from "images/storeLogos/drawWith.png";
import DrawWithCardLogo from "images/gameCards/logo/drawWith.png";
import DrawWithCardBackground from "images/gameCards/background/drawWith.png";
import DrawWithMobilePreview1 from "images/gameStorePreviews/drawWith/drawwith-mobile-1.png";
import DrawWithMobilePreview2 from "images/gameStorePreviews/drawWith/drawwith-mobile-2.png";
import DrawWithDesktopPreview1 from "images/gameStorePreviews/drawWith/drawwith-desktop-1.png";
import DrawWithDesktopPreview2 from "images/gameStorePreviews/drawWith/drawwith-desktop-2.png";
import DaveTinyCard from "images/tinyCards/Draw Along with Dave.png";

// The Chameleon
import ChameleonLogo from "images/games/ChameleonLogo.png";
import ChameleonStoreBackground from "images/storeBackgrounds/theChameleon.png";
import ChameleonStoreLogo from "images/storeLogos/theChameleon.png";
import ChameleonCardLogo from "images/gameCards/logo/theChameleon.png";
import ChameleonCardBackground from "images/gameCards/background/theChameleon.png";
import ChameleonMobilePreview1 from "images/gameStorePreviews/theChameleon/chameleon-mobile-1.png";
import ChameleonMobilePreview2 from "images/gameStorePreviews/theChameleon/chameleon-mobile-2.png";
import ChameleonDesktopPreview1 from "images/gameStorePreviews/theChameleon/chameleon-desktop-1.png";
import ChameleonDesktopPreview2 from "images/gameStorePreviews/theChameleon/chameleon-desktop-2.png";
import ChameleonTinyCard from "images/tinyCards/The Chameleon.png";

// Colour Brain
import ColourBrainLogo from "images/games/ColourBrainLogo.png";
import ColourBrainStoreBackground from "images/storeBackgrounds/colourBrain.png";
import ColourBrainStoreLogo from "images/storeLogos/colourBrain.png";
import ColourBrainCardLogo from "images/gameCards/logo/colourBrain.png";
import ColourBrainCardLogoJunior from "images/gameCards/logo/colourBrainJuniorLogo.png";
import ColourBrainCardBackground from "images/gameCards/background/colourBrain.png";
import ColourBrainMobilePreview1 from "images/gameStorePreviews/colourBrain/colourbrain-mobile-1.png";
import ColourBrainMobilePreview2 from "images/gameStorePreviews/colourBrain/colourbrain-mobile-2.png";
import ColourBrainDesktopPreview1 from "images/gameStorePreviews/colourBrain/colourbrain-desktop-1.png";
import ColourBrainDesktopPreview2 from "images/gameStorePreviews/colourBrain/colourbrain-desktop-2.png";
import ColourBrainTinyCard from "images/tinyCards/Colourbrain.png";

// Anagram Magic
import AnagramMagicLogo from "images/games/anagram-magic-logo.png";
import AnagramMagicCardLogo from "images/gameCards/logo/anagram-magic-shop-logo.png";
import AnagramMagicBackground from "images/storeBackgrounds/anagram-magic-background.png";
import AnagramMagicMobilePreview1 from "images/gameStorePreviews/brainGames/anagramMagic/anagramMagic-mobile-1.png";
import AnagramMagicMobilePreview2 from "images/gameStorePreviews/brainGames/anagramMagic/anagramMagic-mobile-2.png";
import AnagramMagicDesktopPreview1 from "images/gameStorePreviews/brainGames/anagramMagic/anagramMagic-desktop-1.png";
import AnagramMagicDesktopPreview2 from "images/gameStorePreviews/brainGames/anagramMagic/anagramMagic-desktop-2.png";
import AnagramMagicTinyCard from "images/tinyCards/anagram-magic-tinycard.png";

// Maths Master
import MathsMasterLogo from "images/games/maths-master-logo.png";
import MathsMasterCardLogo from "images/gameCards/logo/maths-master-shop-logo.png";
import MathsMasterBackground from "images/storeBackgrounds/maths-master-background.png";
import MathsMasterMobilePreview1 from "images/gameStorePreviews/brainGames/mathsMaster/mathsMaster-mobile-1.png";
import MathsMasterMobilePreview2 from "images/gameStorePreviews/brainGames/mathsMaster/mathsMaster-mobile-2.png";
import MathsMasterDesktopPreview1 from "images/gameStorePreviews/brainGames/mathsMaster/mathsMaster-desktop-1.png";
import MathsMasterDesktopPreview2 from "images/gameStorePreviews/brainGames/mathsMaster/mathsMaster-desktop-2.png";
import MathsMasterTinyCard from "images/tinyCards/maths-master-tinycard.png";

// Word Wheel
import WordWheelLogo from "images/games/word-wheel-logo.png";
import WordWheelCardLogo from "images/gameCards/logo/word-wheel-shop-logo.png";
import WordWheelBackground from "images/storeBackgrounds/word-wheel-background.png";
import WordWheelMobilePreview1 from "images/gameStorePreviews/brainGames/wordWheel/wordWheel-mobile-1.png";
import WordWheelMobilePreview2 from "images/gameStorePreviews/brainGames/wordWheel/wordWheel-mobile-2.png";
import WordWheelDesktopPreview1 from "images/gameStorePreviews/brainGames/wordWheel/wordWheel-desktop-1.png";
import WordWheelDesktopPreview2 from "images/gameStorePreviews/brainGames/wordWheel/wordWheel-desktop-2.png";
import WordWheelTinyCard from "images/tinyCards/word-wheel-tinycard.png";

// Speedy Sums
import SpeedySumsLogo from "images/games/speedy-sums-logo.png";
import SpeedySumsCardLogo from "images/gameCards/logo/speedy-sums-shop-logo.png";
import SpeedySumsBackground from "images/storeBackgrounds/speedy-sums-background.png";

// Pub Quiz
import PubQuizLogo from "images/games/PubQuizLogo.png";
import PubQuizStoreBackground from "images/storeBackgrounds/pubQuiz.jpg";
import PubQuizStoreLogo from "images/games/PubQuizLogo.png";
import PubQuizCardLogo from "images/gameCards/logo/pubQuiz.png";
import PubQuizCardBackground from "images/gameCards/background/pubQuiz.png";
import PubQuizMobilePreview1 from "images/gameStorePreviews/pubQuiz/pubquiz-mobile-1.png";
import PubQuizMobilePreview2 from "images/gameStorePreviews/pubQuiz/pubquiz-mobile-2.png";
import PubQuizDesktopPreview1 from "images/gameStorePreviews/pubQuiz/pubquiz-desktop-1.png";
import PubQuizDesktopPreview2 from "images/gameStorePreviews/pubQuiz/pubquiz-desktop-2.png";


// YCSU
import YCSULogo from "images/games/ycsu-logo.png";
import YCSUStoreBackground from "images/storeBackgrounds/round-BG-pink.png";
import YCSUStoreLogo from "images/games/ycsu-logo.png";
import YCSUCardLogo from "images/games/ycsu-logo.png";
import YCSUCardBackground from "images/gameCards/background/ycsu-cardBG.png";
import YCSUMobilePreview1 from "images/gameStorePreviews/pubQuiz/pubquiz-mobile-1.png";
import YCSUMobilePreview2 from "images/gameStorePreviews/pubQuiz/pubquiz-mobile-2.png";
import YCSUDesktopPreview1 from "images/gameStorePreviews/pubQuiz/pubquiz-desktop-1.png";
import YCSUDesktopPreview2 from "images/gameStorePreviews/pubQuiz/pubquiz-desktop-2.png";

const GameList = [
    
    {
        type: "Game",
        name: "Scrawl",
        pack: 1,
        gameId: "scrawl",
        enabled: true,
        logo: ScrawlLogo,
        description: "A drawing game that can go very\n wrong, very quickly...",
        productId: process.env.REACT_APP_SCRAWL_PID,
        price: 9.99,
        gameURL: process.env.REACT_APP_SCRAWL_URL,
        storeSlug: '/shop/scrawl',
        gameInfo: {
            minPlayers: 3,
            avgRoundLengthText: '20 mins',
            minAge: 8,
            descriptions: [
                {
                    header: "BAD DRAWERS WELCOME",
                    body: "You don't have to be good at drawing to play Scrawl. In fact, the worse you are, the better."
                },
                {
                    header: "SCRAWL STARTS WITH A SILLY PHRASE",
                    body: "You have to draw ludicrous things like, \"A Monkey Knife Fight\" or \"A Haunted Oven\", then pass it on."
                },
                {
                    header: "PASS IT ON",
                    body: "By the time your rubbish drawing gets back to you things will have gone very wrong. Unveil the whole crazy story, funniest scrawler wins."
                },
                //{
                //    header: "WARPED MINDS WELCOME",
                //    body: "We've heard what some of you have been doing on Scrawl, and we thought sure. Why not? For those with less innocent minds, an optional 17+ game mode is now available!"
                //}
            ],
            //banner: "100 New Scrawls!",
        },
        addonIds: ["scrawl_adult"],
        gameUpsellIds: ["can_of_squirms", "draw_with", "herd_mentality"],
        meta: {
            cardLogo: ScrawlCardLogo,
            cardBackground: ScrawlCardBackground,
            cardPriceColour: '#EC0080',
            storeBackground: ScrawlStoreBackground,
            storeBackgroundColour: '#FFD900',
            storeInfoBackground: '#FFFFFF',
            storeLogo: ScrawlStoreLogo,
            storeAccentColour: '#212B3D',
            gameMenuTextColour: '#ffffff',
            gameMenuBackgroundColour: '#EA085E',
            previewImages: {
                mobile1: ScrawlMobilePreview1,
                mobile2: ScrawlMobilePreview2,
                desktop1: ScrawlDesktopPreview1,
                desktop2: ScrawlDesktopPreview2,
            },
            tinyCard: ScrawlTinyCard,
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: '\u00A39.99',
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Game",
        name: "Herd Mentality",
        pack: 1,
        gameId: "herd_mentality",
        enabled: true,
        logo: HerdLogo,
        description: "One black and white mission: \ndon't be the odd one out!",
        productId: process.env.REACT_APP_HERD_PID,
        price: 9.99,
        gameURL: process.env.REACT_APP_HERD_URL,
        storeSlug: '/shop/herd-mentality',
        gameInfo: {
            minPlayers: 3,
            avgRoundLength: 1800,
            avgRoundLengthText: '20 mins',
            minAge: 12,
            descriptions: [
                {
                    header: "DON'T HAVE A COW MAN!",
                    body: "In Herd Mentality, if your answer is the odd one out, you'll be saddled with the Pink Cow - and you don't want that."
                },
                {
                    header: "NAME A FOOD WITH A HOLE IN IT...",
                    body: "Do you think your answer would match everyone else's?"
                },
                {
                    header: "\"THE BEST PARTY GAME EVER CREATED\"",
                    body: "\"Herd Mentality is guaranteed to have you in stitches... a fabulous game to play with families\" - TechRadar 2022"
                }
            ]
        },
        addonIds: [],
        gameUpsellIds: ["can_of_squirms", "colour_brain", "scrawl"],
        meta: {
            cardLogo: HerdCardLogo,
            cardBackground: HerdCardBackground,
            cardPriceColour: '#10232B',
            storeBackground: HerdStoreBackground,
            storeBackgroundColour: '#FFFFFF',
            storeInfoBackground: '#FFFFFF',
            storeLogo: HerdStoreLogo,
            storeAccentColour: '#000000',
            gameMenuTextColour: '#000000',
            gameMenuBackgroundColour: '#ffffff',
            previewImages: {
                mobile1: HerdMobilePreview1,
                mobile2: HerdMobilePreview2,
                desktop1: HerdDesktopPreview1,
                desktop2: HerdDesktopPreview2,
            },
            tinyCard: HerdTinyCard,
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: "\u00A39.99",
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Game",
        name: "Pub Quiz",
        pack: null,
        gameId: "pub_quiz",
        enabled: true,
        logo: PubQuizLogo,
        description: "Race to the Bar in this fast-paced Pub Quiz!",
        productId: process.env.REACT_APP_PQ_PID,
        gameURL: process.env.REACT_APP_PUBQUIZ_URL,
        storeSlug: '/shop/pub-quiz',
        gameInfo: {
            minPlayers: 2,
            avgRoundLength: 1800,
            avgRoundLengthText: '30 mins',
            minAge: 12,
            descriptions: [
                {
                    header: "CLASSIC PUB QUIZ",
                    body: "Grab your drink, answer questions, and Race to the Bar! Outsmart and outpace to celebrate victory in this fun trivia game."
                },
                {
                    header: "THREE QUIZ ROUNDS",
                    body: "Pick your categories, outwit opponents in three quiz rounds. Bonus chips spice up your strategy."
                },
                {
                    header: "RACE TO THE BAR",
                    body: "Select as many correct answers as you dare, but don't get one wrong! Make it to the bar before the landlord calls time!"
                }
            ]
        },
        addonIds: [],
        gameUpsellIds: ["colour_brain", "scrawl", "herd_mentality"],
        meta: {
            cardLogo: PubQuizCardLogo,
            cardBackground: PubQuizCardBackground,
            cardPriceColour: '#10232B',
            storeBackground: PubQuizStoreBackground,
            storeBackgroundColour: '#977069',
            storeLogo: PubQuizStoreLogo,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#545D6E',
            gameMenuTextColour: '#000000',
            gameMenuBackgroundColour: '#69B8B9',
            previewImages: {
                mobile1: PubQuizMobilePreview1,
                mobile2: PubQuizMobilePreview2,
                desktop1: PubQuizDesktopPreview1,
                desktop2: PubQuizDesktopPreview2,
            },
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: "\u00A39.99",
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    },

    {
        type: "Game",
        name: "Chameleon",
        pack: 2,
        gameId: "chameleon",
        enabled: true,
        logo: ChameleonLogo,
        description: "Pick a secret word, then try to\n catch out the guilty player!",
        productId: process.env.REACT_APP_CHAMELEON_PID,
        price: 9.99,
        gameURL: process.env.REACT_APP_CHAMELEON_URL,
        storeSlug: '/shop/the-chameleon',
        gameInfo: {
            minPlayers: 3,
            avgRoundLengthText: '20 mins',
            minAge: 14,
            descriptions: [
                {
                    header: "One Of You Is The Chameleon",
                    body: "In this party game, your job is to blend in and not get caught."
                },
                {
                    header: "Catch That Chameleon!",
                    body: "Everyone else it trying to work out who the Chameleon is. Everyone suspects everyone else at the beginning."
                },
                {
                    header: "One Word Can Give You Away",
                    body: "Using one carefully chosen word based on a secret topic, you each try to smoke out the Chameleon, who doesn't know what the scret topic is."
                }
            ]
        },
        addonIds: [],
        gameUpsellIds: ["colour_brain", "draw_with", "can_of_squirms"],
        meta: {
            cardLogo: ChameleonCardLogo,
            cardBackground: ChameleonCardBackground,
            cardPriceColour: '#007DA5',
            storeBackground: ChameleonStoreBackground,
            storeBackgroundColour: '#000000',
            storeLogo: ChameleonStoreLogo,
            storeInfoBackground: '#EBEF66',
            storeAccentColour: '#029480',
            gameMenuTextColour: '#FFFFFF',
            gameMenuBackgroundColour: '#000000',
            previewImages: {
                mobile1: ChameleonMobilePreview1,
                mobile2: ChameleonMobilePreview2,
                desktop1: ChameleonDesktopPreview1,
                desktop2: ChameleonDesktopPreview2,
            },
            tinyCard: ChameleonTinyCard,
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: "\u00A39.99",
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Game",
        name: "Colour Brain",
        pack: 2,
        gameId: "colour_brain",
        enabled: true,
        logo: ColourBrainLogo,
        description: "A party trivia game where\n every answer is a colour!",
        productId: process.env.REACT_APP_CB_PID,
        price: 9.99,
        gameURL: process.env.REACT_APP_COLOURBRAIN_URL,
        storeSlug: '/shop/colour-brain',
        gameInfo: {
            minPlayers: 2,
            avgRoundLengthText: '20 mins',
            minAge: 12,
            descriptions: [
                {
                    header: "YOU START WITH THE ANSWERS",
                    body: "All the answers you need in Colourbrain are in your hand. And they're all colours!"
                },
                {
                    header: "300 COLOURFUL QUESTIONS",
                    body: "What colour is the lid on full-fat milk... what colour is a sharon fruit... which colour cards would you put down?"
                },
                {
                    header: "QUICK TO LEARN, EASY TO PLAY",
                    body: "Everyone in the family can get involved with Colourbrain. Pick up how to play in minutes."
                },
                {
                    header: "JUNIOR COLOUR BRAIN (INCLUDED)",
                    body: "Keeping the same fun trivia and exciting gameplay, with over 100 family-friendly questions, this new edition is not only fun but also educational, helping kids learn colors and boosting their memory skills."
                }
            ],
            //banner: "Free Junior Mode Update!",
        },
        addonIds: ["colour_brain_junior"],
        gameUpsellIds: ["chameleon", "draw_with", "herd_mentality"],
        meta: {
            cardLogo: ColourBrainCardLogo,
            //cardLogo2: ColourBrainCardLogoJunior,
            cardBackground: ColourBrainCardBackground,
            cardPriceColour: '#10232B',
            storeBackground: ColourBrainStoreBackground,
            storeBackgroundColour: '#FFFFFF',
            storeLogo: ColourBrainStoreLogo,
            //storeLogo2: ColourBrainCardLogoJunior,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#93A2AA',
            gameMenuTextColour: '#000000',
            gameMenuBackgroundColour: '#ffffff',
            previewImages: {
                mobile1: ColourBrainMobilePreview1,
                mobile2: ColourBrainMobilePreview2,
                desktop1: ColourBrainDesktopPreview1,
                desktop2: ColourBrainDesktopPreview2,
            },
            tinyCard: ColourBrainTinyCard,
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: "\u00A39.99",
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Game",
        name: "Draw along with Dave",
        pack: 2,
        gameId: "draw_with",
        enabled: true,
        logo: DrawWithLogo,
        description: "Pick up your paintbrush and\n create your masterpiece!",
        productId: process.env.REACT_APP_DRAWWITHDAVE_PID,
        price: 9.99,
        gameURL: process.env.REACT_APP_DRAWWITH_URL,
        storeSlug: '/shop/draw-along-with-dave',
        gameInfo: {
            minPlayers: 2,
            avgRoundLengthText: '30 mins',
            minAge: 8,
            descriptions: [
                {
                    header: "UNLEASH YOUR INNER ARTIST",
                    body: "Take turns playing as Dave, creating your masterpiece and describing as you go."
                },
                {
                    header: "FOLLOW ALONG... IF YOU CAN",
                    body: "Everyone else, it's your job to listen to Dave's instructions, draw along with them, and try to guess what it's supposed to be!"
                },
                {
                    header: "ANYONE CAN DO IT",
                    body: "If you're a good artist, that's great. If you're a bad artist, that's even better."
                }
            ]
        },
        addonIds: [],
        gameUpsellIds: ["colour_brain", "chameleon", "scrawl"],
        meta: {
            cardBackground: DrawWithCardBackground,
            cardLogo: DrawWithCardLogo,
            cardPriceColour: '#FF5F6B',
            storeBackground: DrawWithStoreBackground,
            storeBackgroundColour: '#093883',
            storeLogo: DrawWithStoreLogo,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#000000',
            gameMenuTextColour: '#000000',
            gameMenuBackgroundColour: '#FECD57',
            previewImages: {
                mobile1: DrawWithMobilePreview1,
                mobile2: DrawWithMobilePreview2,
                desktop1: DrawWithDesktopPreview1,
                desktop2: DrawWithDesktopPreview2,
            },
            tinyCard: DaveTinyCard,
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: "\u00A39.99",
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    },

    {
        type: "Game",
        name: "Anagram Magic",
        pack: 2,
        gameId: "anagram_magic",
        enabled: true,
        logo: AnagramMagicLogo,
        description: "Given nine letters, try to magic up the longest word you can!",
        productId: process.env.REACT_APP_ANAGRAMMAGIC_PID,
        price: 6.99,
        gameURL: process.env.REACT_APP_ANAGRAMMAGIC_URL,
        storeSlug: '/shop/anagram-magic',
        gameInfo: {
            minPlayers: 1,
            avgRoundLength: 600,
            avgRoundLengthText: '10 Mins',
            minAge: 8,
            descriptions: [
                {
                    header: "WORD WIZARDS WELCOME",
                    body: "Find the longest words and impress your friends with your immense vocabulary."
                },
                {
                    header: "UNRAVEL THE ANAGRAM",
                    body: "Every game has a bonus 9 letter anagram, conjured up just for you, can you find the word?"
                },
                {
                    header: "BONUS POINTS BONANZA",
                    body: "Rare letters worth extra points and get the longest word for even more. Unleash your inner wordsmith and flex your linguistic muscles!"
                },
            ]
        },
        addonIds: [],
        gameUpsellIds: ["maths_master", "word_wheel", "scrawl"],
        meta: {
            cardLogo: AnagramMagicCardLogo,
            cardBackground: AnagramMagicBackground,
            cardPriceColour: '#10232B',
            storeBackground: AnagramMagicBackground,
            storeLogo: AnagramMagicLogo,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#0e5e87',
            gameMenuTextColour: '#002026',
            previewImages: {
                mobile1: AnagramMagicMobilePreview1,
                mobile2: AnagramMagicMobilePreview2,
                desktop1: AnagramMagicDesktopPreview1,
                desktop2: AnagramMagicDesktopPreview2,
            },
            tinyCard: AnagramMagicTinyCard,
        },
        pricing: {
            gb: {
                raw: 6.99,
                display: "\u00A36.99",
                currency: "\u00A3"
            },
            us: {
                raw: 9.99,
                display: "\u00249.99",
                currency: "\u0024"
            }
        }
    },

    {
        type: "Game",
        name: "Maths Master",
        pack: 2,
        gameId: "maths_master",
        enabled: true,
        logo: MathsMasterLogo,
        description: "You have a target and six numbers, get as close as possible!",
        productId: process.env.REACT_APP_MATHSMASTER_PID,
        price: 6.99,
        gameURL: process.env.REACT_APP_MATHSMASTER_URL,
        storeSlug: '/shop/maths-master',
        gameInfo: {
            minPlayers: 1,
            avgRoundLength: 600,
            avgRoundLengthText: '10 mins',
            minAge: 8,
            descriptions: [
                {
                    header: "MATH MADNESS",
                    body: "Have you've got what it takes to be a Maths Master? Sharpen your pencils and your wits."
                },
                {
                    header: "THE NUMBERS DON'T LIE",
                    body: "Battle it out with friends and foes alike. Who will rise to the top and become the ultimate number cruncher?"
                },
                {
                    header: "RISING CHALLENGE",
                    body: "Can you keep up as the difficulty increases and prove you're the ultimate maths whiz? Step up, stay sharp, and master the numbers!"
                }
            ]
        },
        addonIds: [],
        gameUpsellIds: ["anagram_magic", "word_wheel", "scrawl"],
        meta: {
            cardLogo: MathsMasterCardLogo,
            cardBackground: MathsMasterBackground,
            cardPriceColour: '#10232B',
            storeBackground: MathsMasterBackground,
            storeLogo: MathsMasterLogo,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#0e6f87',
            gameMenuTextColour: '#000000',
            previewImages: {
                mobile1: MathsMasterMobilePreview1,
                mobile2: MathsMasterMobilePreview2,
                desktop1: MathsMasterDesktopPreview1,
                desktop2: MathsMasterDesktopPreview2,
            },
            tinyCard: MathsMasterTinyCard,
        },
        pricing: {
            gb: {
                raw: 6.99,
                display: "\u00A36.99",
                currency: "\u00A3"
            },
            us: {
                raw: 9.99,
                display: "\u00249.99",
                currency: "\u0024"
            }
        }
    },

    {
        type: "Game",
        name: "Word Wheel",
        pack: 2,
        gameId: "word_wheel",
        enabled: true,
        logo: WordWheelLogo,
        description: "Craft words using the central letter and show your linguistic finesse!",
        productId: process.env.REACT_APP_WORDWHEEL_PID,
        price: 6.99,
        gameURL: process.env.REACT_APP_WORDWHEEL_URL,
        storeSlug: '/shop/word-wheel',
        gameInfo: {
            minPlayers: 1,
            avgRoundLength: 600,
            avgRoundLengthText: '10 mins',
            minAge: 8,
            descriptions: [
                {
                    header: "THE WHEEL OF WORDCRAFT",
                    body: "Test your word prowess and craft words that revolve around the central letter and showcase your linguistic finesse."
                },
                {
                    header: "ITS A WORD WHEEL RIVALRY",
                    body: "Challenge your friends to a word wheel battle! In this fast pace spelling game!"
                },
                {
                    header: "MAXED OUT WORDPLAY",
                    body: "Score big with longer words! But just remember your against the clock."
                }
            ]
        },
        addonIds: [],
        gameUpsellIds: ["anagram_magic", "maths_master", "scrawl"],
        meta: {
            cardLogo: WordWheelCardLogo,
            cardBackground: WordWheelBackground,
            cardPriceColour: '#10232B',
            storeBackground: WordWheelBackground,
            storeLogo: WordWheelLogo,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#703499',
            gameMenuTextColour: '#000000',
            previewImages: {
                mobile1: WordWheelMobilePreview1,
                mobile2: WordWheelMobilePreview2,
                desktop1: WordWheelDesktopPreview1,
                desktop2: WordWheelDesktopPreview2,
            },
            tinyCard: WordWheelTinyCard,
        },
        pricing: {
            gb: {
                raw: 6.99,
                display: "\u00A36.99",
                currency: "\u00A3"
            },
            us: {
                raw: 9.99,
                display: "\u00249.99",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Game",
        name: "Can of Squirms",
        pack: 1,
        gameId: "can_of_squirms",
        enabled: true,
        logo: SquirmsLogo,
        description: "Awkward questions that you \ncan't un-ask!",
        productId: process.env.REACT_APP_COS_PID,
        price: 9.99,
        gameURL: process.env.REACT_APP_COS_URL,
        storeSlug: '/shop/can-of-squirms',
        gameInfo: {
            minPlayers: 3,
            avgRoundLengthText: '20 mins',
            minAge: 14,
            descriptions: [
                {
                    header: "Cult-starter, drug lord, occasional deviant",
                    body: "Facing some seriously awkward questions, two players go head-to-head. They have to decide who best fits the answer to the question - without conferring."
                },
                {
                    header: "Place your bets",
                    body: "Meanwhile, the other teams are placing side bets: will this pair agree or disagree?"
                },
                //{
                //    header: "One Word Can Give You Away",
                //    body: "Can of Squirms is the ultimate party game for people who are not easily offended. Play it as a drinking game for reduced awkwardness, or stone cold sober to feel the full power of the squirm."
                //}
            ]
        },
        addonIds: [],
        gameUpsellIds: ["herd_mentality", "chameleon", "scrawl"],
        meta: {
            cardLogo: SquirmsCardLogo,
            cardBackground: SquirmsCardBackground,
            cardPriceColour: '#10232B',
            storeBackground: SquirmsStoreBackground,
            storeBackgroundColour: '#04FF79',
            storeLogo: SquirmsStoreLogo,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#212B3D',
            gameMenuTextColour: '#ffffff',
            gameMenuBackgroundColour: '#202B3E',
            previewImages: {
                mobile1: SquirmsMobilePreview1,
                mobile2: SquirmsMobilePreview2,
                desktop1: SquirmsDesktopPreview1,
                desktop2: SquirmsDesktopPreview2,
            },
            tinyCard: SquirmsTinyCard,
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: "\u00A39.99",
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    },
    //{
    //    name: "Speedy Sums",
    //    pack: 2,
    //    gameId: "speedy_sums",
    //    logo: SpeedySumsLogo,
    //    description: "Gotta go fast",
    //    productId: process.env.REACT_APP_SPEEDYSUMS_PID,
    //    price: 9.99,
    //    gameURL: process.env.REACT_APP_SPEEDYSUMS_URL,
    //    storeSlug: '/shop/speedy-sums',
    //    gameInfo: {
    //        minPlayers: 1,
    //        avgRoundLength: 1800,
    //        avgRoundLengthText: '15 mins',
    //        minAge: 8,
    //        descriptions: [
    //            {
    //                header: "SPEEDY",
    //                body: "add em fast..."
    //            }
    //        ]
    //    },
    //    gameUpsellIds: ["anagram_magic", "maths_master", "word_wheel"],
    //    meta: {
    //        cardLogo: SpeedySumsCardLogo,
    //        cardBackground: SpeedySumsBackground,
    //        cardPriceColour: '#10232B',
    //        storeBackground: SpeedySumsBackground,
    //        storeLogo: SpeedySumsLogo,
    //        storeInfoBackground: '#FFFFFF',
    //        storeAccentColour: '#93A2AA',
    //        gameMenuTextColour: '#000000',
    //        previewImages: {
    //            mobile1: ColourBrainMobilePreview1,
    //            mobile2: ColourBrainMobilePreview2,
    //            desktop1: ColourBrainDesktopPreview1,
    //            desktop2: ColourBrainDesktopPreview2,
    //        },
    //    },
    //    pricing: {
    //        gb: {
    //            raw: 4.99,
    //            display: "\u00A34.99",
    //            currency: "\u00A3"
    //        },
    //        us: {
    //            raw: 4.99,
    //            display: "\u00244.99",
    //            currency: "\u0024"
    //        }
    //    }
    //},
    {
        type: "Game",
        name: `You Cant Say "Umm"`,
        pack: 2,
        gameId: "cant_say_umm",
        enabled: false,
        logo: YCSULogo,
        description: "Describe weird things in this board game without... err... umm... hesitating.",
        productId: process.env.REACT_APP_YCSU_PID,
        price: 9.99,
        gameURL: process.env.REACT_APP_YCSU_URL,
        storeSlug: '/shop/you-cant-say-umm',
        gameInfo: {
            minPlayers: 4,
            avgRoundLengthText: '20 mins',
            minAge: 12,
            descriptions: [
                {
                    header: "UMM!",
                    body: "Describe ridiculous word combos for your team to guess before the time runs out... but don't forget, you can't say umm!"
                },
                {
                    header: "ALWAYS MIXING THINGS UP",
                    body: `You're not just avoiding "umm"... this is a card game for adults and families that has loads of new challenges, like 'change seats for every right answer' and 'do everything in a high-pitched voice'.`
                },
                {
                    header: "MADE FOR GAME NIGHT",
                    body: "Ding ding ding! The noisy bell, group celebrations and tongue-tied slip ups make this a hilarious party game for family and adults on game night."
                }
            ],
            banner: "BETA",
        },
        addonIds: [],
        gameUpsellIds: ["scrawl", "chameleon", "herd_mentality"],
        meta: {
            cardLogo: YCSUCardLogo,
            //cardLogo2: ColourBrainCardLogoJunior,
            cardBackground: YCSUCardBackground,
            cardPriceColour: '#10232B',
            storeBackground: YCSUStoreBackground,
            storeBackgroundColour: '#FFFFFF',
            storeLogo: YCSUStoreLogo,
            //storeLogo2: ColourBrainCardLogoJunior,
            storeInfoBackground: '#FFFFFF',
            storeAccentColour: '#3B4046',
            gameMenuTextColour: '#000000',
            gameMenuBackgroundColour: '#ffffff',
            previewImages: {
                mobile1: YCSUMobilePreview1,
                mobile2: YCSUMobilePreview2,
                desktop1: YCSUDesktopPreview1,
                desktop2: YCSUDesktopPreview2,
            },
        },
        pricing: {
            gb: {
                raw: 9.99,
                display: "\u00A39.99",
                currency: "\u00A3"
            },
            us: {
                raw: 14.50,
                display: "\u002414.50",
                currency: "\u0024"
            }
        }
    }
];

export default GameList;
